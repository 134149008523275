@import "../../styles/customvariables";
@import "../../styles/custommixin";
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

@include toastMessageCss();

.gsamProgressbar__thumb {
    overflow: visible;
    height: 10px !important;
    background-color: #CCCCCC;
    border-radius: 10px;
}
.gsamProgressbar__thumb__fill {
    background-color: #047A9C;
    height: 10px;
    border-radius: 10px;
}

.passwordchange{
    padding: 14px;
    &__heading{
        font-size:$fontsize;
        letter-spacing: 0px;
        color: $pearson-charcoal;
        opacity: 1;
        @include OpenSansExtraBold();
    }
    &__list{
        list-style-type: disc;
        padding-left: 1em;
        &--item{
            font-size:$fontsize;
            letter-spacing: 0px;
            color: $pearson-charcoal;
            opacity: 1;
            padding-top: 12px;
            @include OpenSansRegular();
        }
    }
    &__sublist{
        padding-left: 1em;
        list-style-type: circle;
        &--item{
            font-size:$fontsize;
            letter-spacing: 0px;
            color: $pearson-charcoal;
            opacity: 1;
            padding-top: 12px;
            @include OpenSansRegular();
        } 
    }
}
.toggle-pwd-btn{
    @include absolute(right 20px top 32px);
    padding: 0;
    &.--chng{
        position: absolute !important;
        top:29px;
        right: 10px;
    }
    &:disabled{
        opacity: 0.5;
        pointer-events: none;
    }
}
