.ogaNav__lst__grp__lnk.active__activehub {
    border-left: 4px solid #9e007e !important;
    font-weight: bold;
    color: rgb(51, 51, 51);
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ogaNav__lst__grp__lnk.activehub {
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ogaNav__lst__grp__lnk.active__activehub.ogaNav__lst__grp__lnk.student-menu {
    background: initial;
}

.ogaNav__lst__grp__lnk.activehub {
    color: rgb(51, 51, 51);
}

.ogaNav__lst__grp__lnk.activehub:last-child {
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ogaNav__lst__grp__lnk.active__activehub {
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ogaNav__lst__grp__lnk:last-child {
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ogaNav__lst__grp__lnk.active__activehub:last-child {
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ogaNav__lst__grp__lnk__txt {
    text-align: initial;
}

.ogaNav__lst__grp__lnk.student-menu,
.ogaNav__lst__grp__lnk.student-menu:last-child {
    font-size: 1rem;
    border-bottom: 0px !important;
    border-top: 0px !important;
    color: rgb(51, 51, 51);
}

.ogaNav__lst__grp__lnk__txt.student-menu-item {
    padding-left: 0px;
}

.mobileAdminLink {
    padding: 0.5rem 0rem !important;
}
.mobileDashboardLink.active {
    .MuiTreeItem-label {
        font-weight: 700;
    }
}