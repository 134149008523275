.repotlink {
    background: #005D83;
    border-radius: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    cursor: pointer;
    padding: 7px 15px 7px 15px;
}

.header {
    margin-bottom: 19px;
    margin-bottom: 10px;
    font-size: xx-large;
    font-weight: 700;
    color: #252525;
    padding: 0 0 0 89px;
    font-size: 25px;
}

.subheader {
    color: #252525;
    font-size: 16px;
    margin-left: 34px;
    width: 560px;
    font-family: "Open Sans", serif;
    font-weight: 600;
}

.accdeactivate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 850px;
    height: 300px;
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1607843137);
    border: 1px solid #C7C7C7;
    border-radius: 8px;
    opacity: 1;
}

.button-message {
    font-size: 16px;
    font-weight: 600;
    width: 560px;
}

.rightcnt {
    width: 70%;
}

.messages {
    padding-left: 30px;
    font-size: 15px;
    font-weight: 600;
    width: 560px !important;
    color: #252525;
}