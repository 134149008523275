@import "../../styles/customvariables";
@import "../../styles/custommixin";
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

.gwWraper_footer {
  z-index: 4;
  position: relative;
  bottom: 0;
  width: 100%;
}

.gwFooter {
  //margin: 48px 0 0;
  padding: 37px 0px 36px;
  background-color: #d9dfe7;
  font-size: 0.875rem;
  color: #000d1c;
  width: 100%;
  @include bp-small {
    padding: 16px 0px;
  }
  &__links {
    width: 96%;
    display: flex;
    justify-content: center;
    @include bp-small {
      display: flex;
      width: 100%;
      text-align: left;
      font-size: 0.75rem;
    }
    &__ul {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include bp-small {
        display: inline-flex;
      }
    }
    &__li {
      float: left;
      & a {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #000d1c !important;
        cursor: pointer;
      }
      & span {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #000d1c !important;
      }
      &:last-child {
        &:after {
          content: " ";
          padding: 0 10px;
        }
      }
      @include bp-small {
        position: relative;
        float: left;
        display: block;
        width: 100%;
        padding-bottom: 8px;
      }
      a {
        color: #fff;
        text-decoration: underline;
        &:hover {
          color: #fff;
        }
      }
      &:after {
        content: "|";
        padding: 0 10px;
        @include bp-medium {
          padding: 0 4px;
        }
        @include bp-small {
          content: '';
          width: 24px;
          height: 1px;
          background: #000d1c;
          bottom: 0px;
          left: 0;
          position: absolute;
        }
      }
    }
  }
  &__copy {
    width: 40%;
    @include bp-small {
      margin-top: 10px;
      width: 100%;
      text-align: center;
    }
  }
  &__access {
    @include absolute(right 15px top 0);
    line-height: 1;
    .gwaccess__link {
      color: #fff;
      text-decoration: underline;
      font-size: 1.125rem;
      cursor: pointer;
    }
    .gwaccess__dropdown {
      @include absolute(right 0 bottom 50px);
      min-width: 200px;
      &--style {
        border: 1px solid #007fa3;
        border-radius: 10px;
        text-align: center;
        background: #fff;
        font-size: 0.875rem;
        width: 100%;
        li {
          border-bottom: solid 1px #ececec;
          padding: 15px 10px;
          color: #707070;
          cursor: pointer;
        }
      }
      &--size {
        border: 1px solid #007fa3;
        border-radius: 10px;
        background: #fff;
        margin-top: 10px;
        width: 100%;
        label {
          color: #707070;
          margin: 10px;
        }
        .gwfontsize__decrease {
          button {
            color: #007fa3;
            font-size: 2rem;
            cursor: pointer;
          }
        }
        .gwfontsize__increase {
          font-size: 1.125rem;
          button {
            color: #007fa3;
            font-size: 2rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.gwbutton {
  background: transparent;
}
.gwlabel {
  margin: 0;
}
.disable__fontoption button.gwbutton {
  color: #e7e7e7 !important;
  pointer-events: none !important;
}

.gwFooter_d_one {
  display: none !important;
}

.gwFooter_ml_0 {
  margin-left: 0 !important;
}

.gwFooter_align_items_center {
  align-items: center !important;
}

.gwFooter_row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.gwFooter_py_1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.gwFooter_justify_content_center {
  justify-content: center !important;
}

.gwFooter_border_right {
  border-right: 1px solid #dee2e6 !important;
}

.gwFooter_col_6 {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.gwFooter_d_flex {
  display: flex !important;
}

.gwbutton {
  background: transparent;
}

.gwlabel {
  margin: 0;
}

/*# sourceMappingURL=gw-footer.css.map */
