@import '../../styles/custommixin';
.gwSpaWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.gwSpaWrapper__tabs {
  flex-grow: 0;
  flex-shrink: 0;
}

.gwSpaWrapper__iframe {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  // GSAM-2604: Adding extra space on top for mobile devices
  // @include bp-small {
  //   padding-top: 154px;
  // }
}
