@import '../../styles/custommixin';
@import '../../styles/customvariables';

.ogaWrapper{
    a {
        outline : none;
    }
    //margin-bottom: 100px;
    @include ogaWrapper();
    padding-left: 230px;
    
    @include spa-grid-container();
    &--eve{
        padding: 24px;
        @include grid-template-rows(minmax(40px , auto));
    }
    &-title{
        @include OpenSansSemibold();
        font-size: 1.250rem;
    }
    &-headtitle{
        @include OpenSansSemibold();
        font-size: 1.875rem;
        font-weight: bold;
    }
    &-view{
        border-bottom: 1px solid #e7e7e7;
        padding: 0 0 16px;
        width: 90%;
        @include bp-large{
            width: 93%;
        @include bp-small{
                width: 94%;
                @include bp-verysmall{
                    width: 94%;
                    @include bp-extrasmall{
                        width: 90%;
                    }
                }
            }
        }
        &-result{
            &-txt{
                @include OpenSansRegular();
                font-size: 1rem;
                color:#252525;
                & span{
                    @include OpenSansSemibold();
                }
            }
        }
    }
    &-container{
        width: 93%;
        margin-bottom: 100px;
        display: flex;
        flex-wrap: wrap;
        @include bp-medium{
            width: 100%;
        }
    }
    &-paginationfootr{
        display: flex;
        max-width: 941px;
        box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.05);;
        border: 1px solid rgba(151, 151, 151, 0.07);;
        opacity: 1;
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 16px;
        @include bp-verysmall{
            display: grid;
            padding: 19px;
        }
        div{
            @include bp-verysmall{
            padding: 4px 0px;}
        }
        
    }
    &-sminpun{
        width: 65px;
    }
    &-elementalign{
        display: flex;
    }
}
.lessonPlanWrapper {
    .ogaNav__ctn{
        .ogaNav__ctnmnu{
            display: none !important;
        }
        .ogaNav__lst__grp__lnk{
            color: #333333;
            font-family: 'Open Sans', serif;
            font-weight: 400;
            padding: 8px 16px;
            border-left: 3px solid transparent;
        }
        .ogaNav__lst__grp__lnk.active {
            font-weight: 600;
            border-left: 3px solid #9e007e !important;
        }
    }
}
.visibility-hidden{
    visibility: hidden;
}
.side-nav__lst .gwSidebaritems .gwTabitems__link.active{
    border-bottom: none !important
}
.search-container {
    .form-control{
        border: 1.5px solid #b6bfcc !important;
    }
}
.find-lesson-title {
    margin-bottom: 10px;
}
.align-class{
    line-height: 1;
}
.gsam-card{
    &__footer{
        padding: 16px 24px;
        color: $pearson-charcoal;
        &__pagedata{
            font-size: 1rem;
            color: $pearson-charcoal;
            @include OpenSansRegular();
            @include bp-small{
               font-size: $fontsize;
                
            }
         }
    }
}
.userDropitems--v2{
    &.-newpagedrop{
        text-align: center;
        right: 0;
        bottom: 36px;
        will-change: transform;
        width: 54px;
        left: 6px;
        @include bp-verysmall{
          bottom: 45px;
        }
      }
}

.userDropMenu--v2{
    max-width: 300px;
    width:300px;
    position:relative;
    &__sminput{
        width: 54px !important;
        margin-left: 6px;
    }
}
.pagination__txt.active{
    border-bottom: 2px solid #252525;
}

.gsamblk{
    height:144px;
    box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.05);;
    border: 1px solid rgba(151, 151, 151, 0.07);;
    opacity: 1;
    background-color: #FFFFFF;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-top: 15px;
    width: 294px;
    border-radius: 8px;
    padding:16px 24px;
    cursor: pointer;
    @include bp-large{
        width: 30%;
    }
    @include bp-medium{
        width: 44%;
    }
    @include bp-verysmall{
        width: 100%;
    }
    &__txt-wrp{
        height:100%;
        width:100%;
        &__img{
            height: auto;
            max-width: 96px;
        }
        &__txt{
            color:#252525;
            font-size:1rem;
            @include OpenSansSemibold;
            max-width:100%;
            margin: 0;
            padding: 2px 0 2px 15px;
            line-height: 20px;
            //word-break: normal;
            word-break: break-word;
            overflow: hidden;
        }
    }
}