@import "../../styles/custommixin";
// @import "~common-components-spa/dist/styles/main.scss";

.gwWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
  @include bp-small {
    min-height: 74vh;
  }
  &_header {
    background-color: #282c34;
    min-height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .spa-container {
    width: calc(100% - 240px);
    margin-left: 240px;
    flex-grow: 1;
  }

  .nav-hidden {
    width: 100%;
    margin-left: 0;
    flex-grow: 1;
  }

  &_content {
    flex: 1;
    // margin: 0 auto;
    // the whole width should be available for the spa
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &_footer {
    background-color: #282c34;
    min-height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}

*,
::after,
::before {
  box-sizing: border-box !important;
}

.assessment-dashboard {
  overflow: hidden;
  .gwIframe {
    .min-h-screen-bar {
      padding-bottom: 200px;
    }
  }
  .gwWraper_footer {
    display: none;
  }
}