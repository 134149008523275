// Material Effect for popup
.confirm-modal {
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 650px;
            margin: 1.75rem auto;
        }
    }
}

.err-popup{
    .modal-content {
        position: relative;
    }
    p{
        font-size: $fontsize;
        padding: 4px 0;
        color: #252525;
    }
    h3{
        font-size: 1.25rem;
        color:#252525;
        padding: 8px 0 4px;
        @include OpenSansSemibold;
    }
    hr{
        width: 75%;
    }
    &__success{
        p{
            padding-top:15px;
        }
    }
    &__hide-show{
        button{
            color: #007FA3;
            @include OpenSansLight;
            font-size: 0.75rem;
            text-decoration: underline;
        }
        .show-blk{
            &__list{
                padding: 0 3rem 0 4.5rem;
                text-align: left;
                max-height: 90px;
                overflow-y: auto;
                @include vscrollbars();
                li{
                    font-size: $fontsize;
                    color: #252525;
                }
            }
        }
    }
    &__bgimg {
        @include absolute(top 47% left 50%);
        @include transform(translate(-50%, -50%));
        width: 85%;
        z-index: 1;
    }
    button{
        z-index: 2;
    }
}

.text-green{
    color: #81C36C !important;
}
.text-red{
    color: #F86D70 !important;
}
.text-blue{
    color: #007FA3 !important;
}
.closeicon {
    background: transparent;
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
    cursor: pointer;
    padding: 5px 15px;
}
.errmodal {
    .modal-content {
        border-radius: 2px;
    }
    &__img {
        margin: 1em 0 1.875em 0;
    }
    &__heading {
        color: #252525;
        font-size: 1.25em;
        @include OpenSansSemibold;
    }
    &__txt{
        color: #000000;
        font-size: 0.875rem;
    }
    &__info {
        color: #F86D70;
        font-size: 0.875rem;
        margin: 0.625em 0 1.6875em 0;
    }
    &__subinfo {
        color: #000000;
    }
}

.unable-data{
    color: #C77B23;
}
// Material Effect for popup
.modal {
	z-index: 99999;
}
.modal-backdrop {
	z-index: 9999;
}
.modal-content{
    border: 0;
    border-radius: 0;
    background: #fff;
    padding: 30px;
}
.modal.show .modal-content,
.modal .modal-content {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.modal.show .modal-content {
    @include animation('anim-open');
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.modal.close .modal-content,
.modal .modal-content {
    @include animation('anim-close');
}
.modal {
    &__header{
        background: #fff;
        border-radius: 0;
        border: 0;
        color: #252525;
        padding:0 0 1rem;
    }
    &__content {
        border: 0px;
        border-radius: 0px;
        padding: 30px 30px;
    }
    &__dialog {
        max-width: 600px;
        //margin: 0 auto;
        .modal-content{
            @include box-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        }
    }
    &__body {
        padding: 0.5rem 0 1rem;
        background: #fff;
    }
    &__footer {
        border-top: 0px;
        border-radius: 0;
        background: #fff;
        padding: 1rem 0 o;
    }
}

//---------------------------------------------------

@include keyframes(anim-open) {
    0% {
        opacity:0;
        -webkit-transform: scale3d(0, 0, 1);
    }

    100% {
        opacity:1;
        -webkit-transform: scale3d(1, 1, 1);
    }
}
@include keyframes(anim-close) {
    0% {
        opacity:1;
    }

    100% {
        opacity:0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
    }
}
