@import '../../styles/custommixin';
@import '../../styles/customvariables';

/* spinner loader start */
.loader-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 5;
}

.loader-wrapper{
  height: auto;
  position: absolute;
  width: 100%;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
}

.gwLoading-spinner {
   display: none;
   margin: 100px auto;
   border: 5px solid #f3f3f3;
   border-radius: 50%;
   border-top: 5px solid $default-pearson-blue;
   width: 60px;
   height: 60px;
   color: transparent;
   @include animation(spin 2s linear infinite);
   &.gwShow{
      display: block;
  }
 }
 @include keyframes(spin) {
  0% {
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}
