@import "../../styles/customvariables";
@import "../../styles/custommixin";
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

@mixin dropdown-list {
  border-bottom: 1px solid $pearson-lavender;
  padding: 5px 10px;
  color: $pearson-night-rider;
  text-transform: capitalize;
  display: block;

  &:last-child {
    border-bottom: 0;
  }
}

@media print {
  .gwWraper_header {
    display: none !important;
  }

  #sidebar-container {
    display: none !important;
  }

  .gwWraper_footer {
    display: none !important;
  }
}

.side-nav__lst__grp__lnk.active {
  border-left: 3px solid #9e007e !important;
}

.shadow-side-nav {
  .hover\:border-gray-400:hover {
    border-left: 3px solid #9e007e !important;
  }

  .border-deep-pink {
    border-left: 3px solid #9e007e !important;
  }
}

.hover\:border-gray-400:hover {
  border-left: 3px solid #9e007e !important;
}

.border-deep-pink {
  border-left: 3px solid #9e007e !important;
}


.gwHeader {
  background-color: $pearson-white;
  width: 100vw;
  z-index: 1000;
  @include fixed;

  @include bp-small {
    // min-height: 95px;
  }

  &--ahub {
    height: 62px;
    box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.08);

    @include bp-small {
      // height:90px;
    }
  }

  &Logo {
    margin: 14px 0;
    max-height: 37px;

    &__img {
      max-height: 36px;
      width: auto;
      cursor: pointer;

      @include bp-verysmall {
        max-height: 29px;
      }

      @include bp-extrasmall {
        max-height: 22px;
      }
    }

    & .mbnone {
      display: block;

      @include bp-large {
        display: none;
      }
    }

    &__note {
      margin: 0 30px;
      user-select: none;

      @include bp-small {
        margin: 0 0 0 15px;
      }

      @include bp-small {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 15px;
        width: 100%;
        text-align: center;
      }

      &txt {
        @include PlayfairRegular;
        font-size: 2rem;
        color: $default-pearson-blue;

        @include bp-medium {
          font-size: 1.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
        }

        @include bp-small {
          max-width: 100%;
        }
      }
    }

    &__show {
      display: inline-block;
    }

    &__hide {
      display: none;
    }
  }
  & .header-right-section {
    column-gap: 10px;
  }  

  & .gwnotifi {
    padding-right: 10px;
  }
  & .gwhelp {
    margin: 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp-small {
      margin: 0 10px 0 0;
    }

    &-icon {
      color: #007FA3;
      cursor: pointer;
      line-height: 26px;
      font-size: 30px;
    }

    .helpHub {
      width: 120px;
      height: 40px;
      font-size: 1rem;
      line-height: 150%;
      font-family: "Open Sans", serif;
      font-weight: 700;
      border: 2px solid #057C9E !important;
      padding: 0 5px !important;
      margin-right: 0 !important;
      font-weight: 700 !important;
      color: #057C9E !important;
      margin: 0 !important;
        &:hover, &:focus {
          color: #057C9E !important;
      }
    
      .helpIcon {
        position: relative;
        top: -1px;
        left: 5px;
      }
    }
  }
}

.gwNotification,
.gwHelp {
  width: 24px;
  height: 24px;
  margin: 8px 10px 6px;
  object-fit: contain;
}

.gwProfile {
  ul {
    margin-bottom: 0rem;
  }

  display: inline-block;
  margin: 8px 10px 6px;

  &__dropcontent {
    @include absolute(top 65px right -170px);
    min-width: 155px;
    background: $pearson-white;
    font-size: 0.813rem;
    background: $pearson-white;
    color: $pearson-night-rider;
    padding: 3px 5px;
    border: solid 1px #e7e7e7;
    @include transition(right 0.5s ease-in-out);

    &__list {
      @include dropdown-list();

      img {
        width: 14px;
        height: 18px;
        margin: 4px 8px 4px 0;
        object-fit: contain;
      }
    }

    & a {
      @include dropdown-list();

      &.no-caps {
        text-transform: none !important;
      }
    }
  }

  &drop__visible {
    right: 1px;
  }

  &__hidden {
    display: none !important;
  }

  &__dropicon {
    padding-right: 10px;
  }

  &__logout {
    padding: 10px 0px;
    color: $pearson-night-rider;
    text-transform: capitalize;
    display: block;

    & .usermenu__dropicon {
      width: 34px;
      height: 25px;
      padding-right: 18px;
    }
  }

  &__dropcontent {
    cursor: pointer;
  }
}

.gwAvatar {
  color: $pearson-night-rider;
  cursor: pointer;

  &__btn {
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    overflow: hidden;
    @include relative;
    background: $pearson-white !important;
    @include border-radius(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #057c9e !important;
    border: 3px solid #057c9e !important;
    font-size: 16px !important;
  }

  .active-hub {
    background: #EA067E !important;
    width: 40px !important;
    height: 40px !important;
    font-size: 1rem !important;
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &__img-blk {
    width: 46px;
    height: 46px;
    cursor: pointer;
    overflow: hidden;
    @include relative;
    background: #003057 !important;
    @include border-radius(50% !important);
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: $pearson-white;
    font-size: 1.25rem !important;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
    cursor: pointer;
  }

  &__text {
    color: $pearson-white;
    @include absolute(top 50% left 50%);
    font-size: 1rem;
    cursor: pointer;
    user-select: none;
    @include transform(translate(-50%, -50%));
  }
}

@include keyframes(profileopen) {
  from {
    right: 0px;
  }

  to {
    right: 0px;
  }
}

@include keyframes(profileclose) {
  from {
    right: 0px;
  }

  to {
    right: -150px;
  }
}

.gwSpaWrapper__tabs {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 65px;
}

.gwSpaWrapper__tabs--ahub {
  & .gwTab {
    margin: 0 auto !important;
    background: transparent;
    border-bottom: none;
    width: 50% !important;
    min-width: 74%;
    position: fixed;
    z-index: 9999;
    padding: 0 !important;
    left: 0;
    top: 6px;

    @media screen and (min-width: 768px) and (max-width: 803px) {
      right: 36px !important;
    }

    @media screen and (min-width: 768px) and (max-width: 902px) {
      margin: 0 0 0 40px !important;

    }

    @include bp-small {
      // top: 28px;
      // width: 100%;
    }

    &items {
      &__link {
        font-size: 1rem;
        @include OpenSansRegular;
        color: #333333;

        &.active {
          border-bottom: 3px solid #9e007e !important;
          @include OpenSansBold;
          color: #333333;

          &:hover {
            border-bottom: 3px solid #9e007e !important;

          }
        }
      }
    }
  }

  & .up-sideblk-tab {
    &.gwTab {
      @include absolute(top 0 left 0);
      border-bottom: solid thin #ececec;
      margin-bottom: 1rem;
      justify-content: start;
      width: 100%;
      background: $pearson-white;
      padding: 15px 25px 5px;

      // @include bp-small{
      //     top: 28px;
      //     width: 100%;
      // }
      &items {
        &__link {
          font-size: 1rem;
          padding: 0 !important;
          @include OpenSansRegular;
          color: #333333;

          &.active {
            border-bottom: 3px solid $gateway-cape-cod;
            @include OpenSansBold;
            color: #333333;

            &:hover {
              border-bottom: 3px solid $gateway-cape-cod;
            }
          }
        }
      }
    }

    & .gwTabitems {
      &__link {
        padding: 0 !important;

        &.active {
          border-bottom: 3px solid $gateway-cape-cod;
        }
      }
    }

    & .gwSpaWrapper__iframe--ahub {
      padding-top: 0;
    }
  }
}

.mobile-logo {
  @include bp-small {
    margin-left: 40px;
  }
}

.mobile-logo-av {
  @include bp-small {
    margin-left: 106px;
  }
}

.side-nav {
  width: 240px;
  background: #ffffff 0% 0% no-repeat padding-box;
  min-height: calc(100vh - 115px);
  z-index: 1;
}

.gwSpaWrapper__tabs--ahub .spa_sidebar.active {
  border-left: 3px solid #9e007e !important;
  font-family: 'Open Sans', serif;
  font-weight: 700;
  color: #333333;
  border-bottom: 0;
  margin-left: 0px;
  padding-left: 15px !important;
}

.gwSpaWrapper__tabs--ahub .spa_sidebar.active:hover {
  border-left: 3px solid #9e007e !important;
  border-bottom: 0;
}

.gwSpaWrapper__tabs--ahub .gwSidebaritems {
  width: 100%;
}

// .d-flex .ogaNav__collapsed {

//   display: none !important;
//   padding-left: 0px !important;
// }
.d-flex .ogaWrapper__collapsed {
  padding-left: 0px;
}

.gwTabitems__link::after {
  content: none !important;
}

.logout-popup-title {
  font-size: 1.25rem;
  margin-bottom: 0;
  line-height: 1.5;
}

.gwnotifi img {
  width: 24px;
  height: 24px;
}

.MuiDrawer-docked {
  .MuiDrawer-paperAnchorDockedLeft {
    background-color: #fff !important;
  }
}

.ogaNav__lst__grp__lnk:last-child {
  border-bottom: none !important;
}

.MuiDrawer-paperAnchorLeft {
  background-color: #fff !important;
}

.appdrawer {
  :focus {
    outline: solid 2px #fff !important;
    outline-offset: 2px;
    box-shadow: none;
    border-radius: inherit;
  }
}
.gwTabitems {
  padding-top: 10px !important;
}