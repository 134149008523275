.active-hub__dashboard {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px;
    height: 100%;
    justify-content: center;
    margin-bottom: 30px;
    .content-area {
        width: 100%;
        display: flex;
        flex-direction: column;
       &__grid {
            display: flex;
            flex-wrap: wrap;
            gap: 32px;
            margin-top: 24px;
            width: 100%;
            h2.title {
                color: #000d1c;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 35px;
                margin-bottom: 12px;
                margin-block: 0em;
                padding-top: 0;
            }
       }
        .title {
            color: #000d1c;
            font-size: 32px;
            font-weight: 700;
            line-height: 50px;
            padding-top: 20px;
       }
    }

    @media screen and (max-width: 740px) {
        padding: 0 20px 20px 20px;
        .content-area {
            h1.title {
                padding-top: 0px;
            }
            margin: 0px;
            display: grid;
            justify-content: center;
            align-items: center;
            text-align: start;
            &__grid {
                display: grid;
                column-gap: unset;
                row-gap: unset;
                .dashboard-gridcard {
                    margin-right: 0px;
                    margin-bottom: 15px;
                }
            }
        }
    }
    @media screen and (min-width: 741px) and (max-width: 1180px) {
        padding: 0 20px 20px 20px;
        .content-area {
            margin: 0px;
            display: flex;
            padding: 15px 0px 0px 15px;
            align-items: flex-start;
            &__grid {
                flex-flow: row wrap;
                display: flex;
                column-gap: unset;
                row-gap: unset;
                .dashboard-gridcard {
                    margin-right: 15px;
                    margin-top: 15px;
                }
            }
        }
    }

    @media (min-width: 1200px) {     
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    @media (min-width: 992px) {     
        max-width: 1140px;
    }

    @media (min-width: 768px) {     
        max-width: 1140px;
    }

    @media (min-width: 576px) {     
        max-width: 1140px;
    }    
}
