.dashboard-gridcard {
    align-items: flex-start;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 25px #d9dfe7;
    color: #5a6572;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 24px;
    text-decoration: none;
    width: 31%;
   .divider {
        width: 100%;
        height: 6px;
        flex-shrink: 0;
        border-radius: 2px;
        background: #057C9E;
  }
    .title {
        color: #000d1c;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        margin-bottom: 12px;
        margin-block: 0em;
    }

    img {
        height: 80px;
        width: 80px;        
        margin-top: 13px;
   }
    p {
        color: #5A6572;
        font-family: "Open Sans", serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top:12px;
        text-align: initial;
    }

    &:hover{
        box-shadow: 0 0 25px #999;
        cursor: pointer;
    }
    
    @media screen and (max-width: 740px) {
        width: 100%;
    }

    @media screen and (min-width: 741px) and (max-width: 1180px) {
        min-width: 48%;
        min-height: 255px;
    }
}