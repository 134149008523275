@import "../../styles/customvariables";
@import "../../styles/custommixin";
@import "~common-components-spa/dist/styles/main.scss";
@import "~common-components-spa/dist/styles/reset.scss";

@import '../../styles/modal';

.cardinfo{
    background: $pearson-white;
    @include box-shadow(0px 7px 8px rgba(0, 0, 0, 0.05));
    border: 1px solid $pearson-moonlight;
    @include border-radius(4px);
    height:100%;
    &__head{
        padding: 15px 24px;
        border-bottom: 0.5px solid $pearson-lavender;
        &__txt{
            font-size: 1.125rem;
            color: $default-pearson-blue;
            @include OpenSansSemibold();
        }
    }
    &__body{
        padding: 15px 24px;        
    }
    &__pra{
        font-size: $fontsize;
        color: $pearson-medium;
        @include OpenSansSemibold();
        margin-bottom: 24px;
    }
    &__infoimg{
        border-right: 1px solid #dee2e6;
        &--img{
            margin-right: 24px;
            &.cursorenable{
                cursor: pointer;
            }
        }
    }
    &__foot{
       padding: 20px 16px 22px;
       @include box-shadow( 0px 7px 8px rgba(0, 0, 0, 0.05));
       &__data{
           font-size: 1rem;
           color: $pearson-charcoal;
           @include OpenSansRegular();
           & span{
            @include OpenSansSemibold();
           }
       }
    }
}
.confirm-password-field > button {
    
    margin-left: 0px;
    padding-right: 0px !important;
    margin-right: -5px;

}
