@import '../../styles/custommixin';
@import '../../styles/customvariables';

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(220, 80);
  }
}

@keyframes radioripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(220, 80);
  }
}

@keyframes radioripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
  }
}

.button {
  background: transparent;
  border: transparent;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 40px;
  padding: 0 20px;
  font-size: 1rem;
  line-height: 30px;
  min-height: 32px;
  font-family: "Open Sans", serif;
  font-weight: 600;
}

.button:last-child {
  margin-left: 12px;
}

.button.btnwidth {
  min-width: 130px;
  min-height: 40px;
}

.button.modalbtn {
  min-width: 125px;
  font-size: 0.875rem;
}

.button.dropdownbtn {
  min-width: 95px;
  font-size: 0.875rem;
}

.button.small {
  font-size: 0.875rem;
}

.button.btnsquare {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.button__primary {
  background-color: #047b9c;
  border: 1px solid #047b9c;
  color: #ffffff;
}

.button__primary:disabled,
.button__primary.disable {
  background-color: #b2d8e3;
  border-color: #b2d8e3;
  pointer-events: none;
}

.button__primary:hover {
  background-color: #005a70;
  border: 1px solid #005a70;
  color: #ffffff;
}

.button__primary__bordered {
  border: 1px solid #047b9c;
  background-color: #fff;
  color: #047b9c;
}

.button__primary__bordered:hover {
  background-color: #005a70;
  border: 1px solid #005a70;
  color: #ffffff;
}

.button__primary__bordered:disabled,
.button__primary__bordered.disable {
  border-color: #007FA3;
  pointer-events: none;
  color: #007FA3;
  opacity: 0.5;
}

.button__bordered {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #252525;
}

.button__bordered:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

.button__default {
  background-color: #e9e9e9;
  border: 1px solid #c7c7c7;
  color: #252525;
}

.button__default:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #252525;
}

.button--v2 {
  background: transparent;
  border: transparent;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 40px;
  padding: 0 20px;
  font-size: 1rem;
  line-height: 32px;
  min-height: 32px;
  font-family: "Open Sans", serif;
  font-weight: 600;
}

.button--v2:last-child {
  margin: 0 0 0 12px;
}

@media only screen and (max-width: 991px) {
  .button--v2__imgfirst.--filtermargin {
    margin-bottom: 5px;
  }
}

.button--v2.btnwidth {
  min-width: 130px;
  min-height: 40px;
}

.button--v2.rosterbtn {
  min-width: 128px;
  min-height: 32px;
  line-height: 30px;
}

.button--v2.instructorbtn {
  min-width: 114px;
  line-height: 30px;
}

.button--v2.crttskbtn {
  display: none;
}

@media only screen and (max-width: 767px) {
  .button--v2.crttskbtn {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .button--v2.crttskbtn:last-child {
    margin-top: 1.5rem;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .button--v2.savetskbtn {
    margin-bottom: 16px;
  }
}

.button--v2.resourcesbtn {
  min-width: 156px;
  line-height: 36px;
}

.button--v2.modalbtn {
  min-width: 130px;
  font-size: 0.875rem;
}

@media only screen and (max-width: 767px) {
  .button--v2.modalbtn {
    min-width: 128px;
    margin: 0px 4px;
  }
}

.button--v2.dropdownbtn {
  min-width: 110px;
  font-size: 0.875rem;
}

.button--v2.assignmentbtn {
  min-width: 80px;
  font-size: 0.875rem;
}

.button--v2.small {
  font-size: 14px;
}

.button--v2.rosterbtnTab {
  min-width: 128px;
  min-height: 32px;
  font-size: 1.125rem;
}

.button--v2.rosterbtnMbTab {
  min-width: 128px;
  min-height: 32px;
  font-size: 1.125rem;
  margin: 8px 0;
}

.button--v2.btnsquare {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.button--v2.locale-btn {
  color: #252525;
  font-family: "Open Sans", serif;
  font-weight: 400;
  border: 1px solid #007FA3;
}

.button--v2.btn36 {
  height: 36px;
}

.button--v2.tasksbtn {
  min-width: 200px;
  height: 36px;
}

.button--v2.btnIcon {
  margin-right: 15px;
  padding: 0px;
}

.button--v2.btnIcon:last-child {
  margin: 0px;
}

.button--v2.roster-land-btn {
  color: #252525;
  border: 1px solid #007FA3;
  border-radius: 8px;
  font-size: 0.875rem;
}

.button--v2.-taskFilBtn {
  background-color: transparent;
}

.button--v2.-taskFilBtn:before {
  background: unset;
}

.button--v2.-taskFilBtn:hover {
  color: #047a9c;
  cursor: pointer;
}

.button--v2.-taskFilBtn:hover:before {
  background-color: unset;
}

.button--v2__primary {
  background-color: #047b9c;
  border: 1px solid #047b9c;
  color: #ffffff;
}

.button--v2__primary:disabled,
.button--v2__primary.disable {
  background-color: #b2d8e3;
  border-color: #b2d8e3;
  pointer-events: none;
}

.button--v2__primary:before {
  background: #005a70;
}

.button--v2__primary:hover {
  color: #ffffff;
}

.button--v2__primary:hover:before {
  background-color: #005a70;
}

.button--v2__primary__bordered {
  border: 1px solid #047b9c;
  background-color: #fff;
  color: #047b9c;
}

.button--v2__primary__bordered:before {
  background: #005a70;
}

.button--v2__primary__bordered:hover {
  color: #ffffff;
}

.button--v2__primary__bordered:hover:before {
  background: #005a70;
}

.button--v2__primary__bordered:disabled,
.button--v2__primary__bordered.disable {
  border-color: #007FA3;
  pointer-events: none;
  color: #007FA3;
  opacity: 0.5;
}

.button--v2__bordered {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #252525;
}

.button--v2__bordered:before {
  background: transparent;
}

.button--v2__bordered:hover {
  border: 1px solid #d9d9d9;
}

.button--v2__bordered:hover:before {
  background: #d9d9d9;
}

.button--v2__bordered:disabled,
.button--v2__bordered.disable {
  pointer-events: none;
  opacity: 0.5;
}

.button--v2__default {
  background-color: #e9e9e9;
  border: 1px solid #c7c7c7;
  color: #252525;
}

.button--v2__default:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #252525;
}

.button--v2__default:disabled,
.button--v2__default.disable {
  pointer-events: none;
  opacity: 0.5;
}

.button--v2__img {
  margin: 0 0 0 15px;
}

.button--v2__link {
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  overflow: visible !important;
}

.button--v2__tablebtn {
  color: #0479AC;
  font-size: 0.875rem;
  text-decoration: underline;
  font-family: "Open Sans", serif;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  border-radius: 0;
}

.button--v2__notablebtn {
  color: #252525;
  font-size: 0.875rem;
  font-family: "Open Sans", serif;
  font-weight: 400;
  pointer-events: none;
}

.button--v2.-stuBtn {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.875rem;
  width: 202px;
  min-height: 36px;
}

.ripple--v2 {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  -webkit-transition: border-color 0.3s, color 0.3s;
  transition: border-color 0.3s, color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.ripple--v2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.7s, opacity 0.7s, background-color 0.7s;
  transition: transform 0.7s, opacity 0.7s, background-color 0.7s;
}

.ripple--v2:after {
  content: "";
  top: 50%;
  left: 50%;
  position: absolute;
  width: 1px;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.ripple--v2:focus:after {
  animation: ripple 1s ease-out;
}

.ripple--v2:hover:before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}


.creation {
  .ht-65 {
    height: 75%;
  }

  // @media only screen and (min-width: 1280px) and (min-width: 1919px) {
  //   .activehub-logo-container {
  //     margin-top: -30px;

  //     .desktop {
  //       display: none;
  //     }
  //   }
  // }

  .gsamblk {
    background: #FFFFFF;
    box-shadow: 0px 0px 25px #D9DFE7;
    border-radius: 14px;
    min-height: 219px;
    min-width: 455px;
    padding: 16px 24px;
    cursor: default;

    .learn-more {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-top: 20px;
      cursor: pointer !important;
    }


    .update-heading {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #000000;
      margin-bottom: 20px;
    }

    @include bp-large {
      width: 30%;
    }

    @include bp-medium {
      width: 44%;
    }

    @include bp-verysmall {
      width: 100%;
    }

    &__txt-wrp {
      height: 100%;
      width: 100%;

      &__txt {
        color: #252525;
        font-size: 1rem;
        @include OpenSansSemibold;
        max-width: 100%;
        margin: 0;
        padding: 2px 0 2px 15px;
        line-height: 20px;
        //word-break: normal;
        word-break: break-word;
        overflow: hidden;
      }
    }
  }

  .gsamblk:hover {
    box-shadow: 0px 7px 8px rgba(151, 151, 151, 0.526);
    border: 1px solid rgba(151, 151, 151, 0.526);
  }

}

.updates {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}




.creation {
  height: 100vh;
}

@media only screen and (max-width: 767px) {
  .creation__logo {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 69px;
    line-height: 94px;
  }
}

.creation__logo {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 100px;
}

.creation__insights {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 100px;
}

.creation__insights__guided {
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .creation__insights {
    font-weight: 300;
    font-size: 49px;
    line-height: 67px;
  }
}

@media only screen and (max-width: 767px) {
  .creation__insights {
    font-weight: 300;
    font-size: 49px;
    line-height: 67px;
  }
}

.creation__wrapleft {
  padding: 0px 0px 0px 125px;

  .mt-link {
    margin-top: 20px;
  }
}


@media only screen and (max-width: 768px) {
  .creation__wrapleft {
    padding: 0px 0px 0px 98px;
  }
}

@media only screen and (max-width: 768px) {
  .creation__logo {
    font-weight: 700;
    font-size: 69px;
    line-height: 94px;
  }
}

.creation__banner {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 70px;
}

@media only screen and (max-width: 768px) {
  .creation__banner {
    max-width: 174px;
    margin: 0 auto;
  }
}

.creation__banner img {
  width: 100%;
}

.creation__wrap {
  background-color: #003057;
  padding: 98px 144px 96px 153px;
  height: 100%;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .creation__wrap {
    padding: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .creation__wrap {
    padding: 31px 16px 40px;
  }
}

.creation__wrap .create-btn {
  color: #ffffff;
  min-width: 386px;
  width: 100%;
  display: block;
  padding: 14px 24px;
  margin-top: 108px !important;
  font-family: "Open Sans", serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 24px;
  min-height: 24px;
}

.creation__wrap .create-btn:hover:before {
  background: #ffffff;
}

.creation__wrap .create-btn:hover {
  color: #003057;
}

@media only screen and (max-width: 767px) {
  .creation__wrap .create-btn {
    min-width: 288px;
    margin-top: 48px !important;
    font-size: 1rem;
    padding: 16px 18px;
  }
}

.creation__wrap__head {
  font-size: 3.25rem;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-weight: bold;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .creation__wrap__head {
    font-size: 2.375rem;
  }
}

.sign-in {
  background: #005D83;
  box-shadow: 0px 0px 17.6087px #D9DFE7;
  border-radius: 42.2609px;
  width: 454px;
  height: 81px;

  .sign-in-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28.1739px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 0.352174px;
    color: #FFFFFF;
  }
}



.sign-in:focus {
  border-radius: 42.2609px !important;
}

.links {
  a {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #007FA3
  }
}

.activehub-logo {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .activehub-text {
    padding-top: 0px;
  }
}


// tablet view
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .prelogin-mobile {
    overflow: hidden !important;

    .gwFooter {
      position: fixed;
      bottom: 0;
    }
  }

  .creation__logo {
    font-weight: 700;
    font-size: 51px;
    line-height: 69px;
  }

  //
  .activehub-logo-container {
    img {
      height: 283px;
      width: 211px;
    }

    position: absolute;
    right: 0px;

    .mobile {
      display: none;
    }
  }

  button.sign-in {
    width: 385px;
    height: 68px;

    .sign-in-text {
      font-weight: 700;
      font-size: 23.9478px;
      line-height: 42px;
    }
  }

  .activehub-text {
    margin-top: 144px;
    margin-left: 72px;

    .creation__logo {
      margin-bottom: 0.12em;
      font-size: 70px;
    }

    .creation__insights {
      font-size: 58.65px;
      line-height: 80px;
    }

    .creation__insights__guided {
      font-size: 58.65px;
      font-weight: 500;
    }
  }

  .signin-container {
    margin-left: 72px;
    margin-top: 48px;
  }

  .links-container {
    margin-top: 20px;

    .mr-link {
      margin-right: 27px;
    }
  }

  .creation {
    .gsamblk {
      min-width: 60%;
      height: auto;
      margin-left: 40px;
    }
  }

  .updates {
    margin-left: 40px;
  }

  div.pt-sign {
    padding-top: 80px;
  }

  .links a {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-decoration-line: underline;
  }
}



//mobile view
@media only screen and (max-width: 767px) {
  .prelogin-mobile {
    overflow: hidden !important;
    .gwFooter {
      position: fixed;
      bottom: 0;
    }
  }

  .creation {
    .gsamblk {
      min-width: auto;
      height: auto;
      margin: 0 0 0 5px !important;
    }
  }

  .activehub-logo-container {

    img {
      width: 101px;
      height: 116px;
    }

    position: absolute;
    right: 0;

    .tablet {
      display: none;
    }
  }

  button.sign-in {
    width: 335px;
    height: 43px;

    .sign-in-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .activehub-text {
    margin-top: 72px;
    margin-left: 20px;

    .creation__logo {
      font-size: 32.13px;
      line-height: 44px;
    }

    .creation__insights {
      font-weight: 300;
      font-size: 35.9495px;
      line-height: 50px;
    }

    .creation__insights__guided {
      font-weight: 500;
      font-size: 36.9495px;
      line-height: 50px;
    }
  }

  .signin-container {
    margin-top: 29px;

    .center-items {
      display: flex;
      justify-content: center;
    }
  }

  .links-container {
    margin-top: 20px;

    .mr-link {
      margin-right: 17px;
    }
  }

  .updates {
    margin-left: 25px;
  }

  div.pt-sign {
    padding-top: 80px;
  }

  .links a {
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    text-decoration-line: underline;
  }

  .grey-line {
    margin-top: 70px;
    margin-right: 70px;
    margin-left: auto;
    width: 70%;
    height: 1.5px;
    background: #D9DFE7;
  }
}



@media only screen and (min-width: 1920px) {

  .prelogin-desktop {
    .gwWraper_footer {
      margin-top: 264px;
    }

    .btn-wrapper {
      margin-top: 83px;
    }

    .gwFooter {
      position: fixed;
      bottom: 0;
    }
  }

  .laptop {
    display: none;
  }

  .activehub-text {
    margin-top: 73px;
  }

  

  .links-container {
    margin-top: 31.2px;

    .mr-link {
      margin-right: 41.6px;
    }
  }


  .activehub-text {

    .creation__logo {
      font-weight: 700;
      font-size: 104px;
      line-height: 142px;
    }

    .creation__insights {
      font-weight: 300;
      font-size: 119.6px;
      line-height: 163px;
    }

    .creation__insights__guided {
      font-weight: 500;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1919px) { 
  .prelogin-desktop {
    .activehub-text {
      margin-top: 73px;
    }
    .gwWraper_footer {
      margin-top: 357px;
    }

    .activehub-logo-container {
      margin-top: -30px;
    }

    .btn-wrapper {
      margin-top: 64px;
    }

    // .gwFooter {
    //   position: fixed;
    //   bottom: 0;
    // }
    
  }

  .desktop {
    display: none;
  }
}