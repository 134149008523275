@import '../../styles/custommixin';
@import '../../styles/customvariables';
.ogaNav {
    border-top: 1px solid #dbdbdb;
    width: 230px;
    background: $pearson-white 0% 0% no-repeat padding-box;
    color: $gateway-dove-gray;
    min-height: 100%;
    @include fixed;
    @include transition(ease-in-out 0.5s);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
    z-index:4;

    // Version 2 Start //
    &--v2{ 
        border-top: 1px solid #dbdbdb;
        width: 230px;
        background: $pearson-white 0% 0% no-repeat padding-box;
        color: $gateway-dove-gray;
        min-height: 100%;
        @include fixed;
        @include transition(ease-in-out 0.5s);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        z-index:4;
        overflow: hidden;
        & .ogaNav{
            &__hamburger{
                display:inline-block;
                margin:0;
                cursor:pointer;
                transition-timing-function:linear;
                transition-duration:.15s;
                transition-property:opacity,filter;
                text-transform:none;
                border:0;
                background-color:transparent;
                height: 24px;
                width: 24px;
                &.is-active{
                    & .ogaNav__hamburger--inner {
                        transition-delay: .12s;
                        transition-timing-function: cubic-bezier(.215,.61,.355,1);
                        transform: rotate(225deg);
                        &:before {
                            top: 0;
                            transition: top .1s ease-out,opacity .1s ease-out .12s;
                            opacity: 0;
                        }
                        &:after {
                            bottom: 0;
                            transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
                            transform: rotate(-90deg);
                        }
                    }
                }        
                &.is-active .ogaNav__hamburger--inner, &.is-active .ogaNav__hamburger--inner:after, &.is-active .ogaNav__hamburger--inner:before {
                    background-color: #007fa3;
                }
                &--box{
                    position:relative;
                    display:inline-block;
                    width: 24px;
                    height: 24px;
                }
                &--inner, &--inner:after, &--inner:before {
                    position: absolute;
                    width: 24px;
                    height: 2px;
                    transition-timing-function: ease;
                    transition-duration: .15s;
                    transition-property: transform;
                    border-radius: 2px;
                    background-color: #007fa3;
                }
                &--inner {
                    top: 50%;
                    display: block;
                    margin-top: 0px;
                    &:before {
                        top: -6px;
                    }
                    &:after {
                        bottom: -6px;
                    }
                    &:after, &:before {
                        display: block;
                        content: "";
                    }
                }
            }
            &__lst{
                &__grp{
                    &__lnk{
                        position: relative;
                        @include transition(all 200ms ease-in);
                        @include transform(scale(1));
                        &:hover {
                            box-shadow: inset 0px 0px 15px #e4e4e4;
                            @include transition(all 200ms ease-in);
                            @include transform(scale(1.1));
                        }
                        &.active {
                            &:hover {
                                @include transition(all 200ms ease-in);
                                @include transform(scale(1.1));
                            }
                        }
                    }
                }
            }
        }
    }
    // Version 2 End //

    &__collapsed {
        width: 60px;
    }
    &__ctn{
        position: relative;
        z-index: 2;
        &mnu{
            width: 100%;
            color: $default-pearson-blue;
            text-align: left;
            font-size: $fontsize;
            padding: 9px 16px;
            cursor: pointer;
            border-radius: unset !important;
            margin-bottom: 0;
            border: unset !important;
            border-bottom: 1px solid $pearson-white-grey !important;
            background-color: $transparent;
            border-left: 3px solid $transparent !important;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            min-height: 44px;
            overflow: hidden;
            align-items: center;
            &__icn{
                width: 24px;
                height: 24px;
                &__show{
                    display: block;
                }
                &__hide{
                    display: none;
                }
            }
            &__txt{
                max-width: 100%;
                padding: 0 10px 0 24px;
                overflow: hidden;
                min-width: 187px;
                word-break: break-word;
            }
        }
    }

}

.side-nav{
    width: 240px;
    background: $pearson-white 0% 0% no-repeat padding-box;
    min-height: calc(100vh - 0px);
    position: fixed;
    z-index: 1;
    margin-top: 65px;
    @include bp-small{
        @include fixed(top 0 left 0);
        height: 100%;
        width: 0;
        z-index: 1;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        &.side-nav__exp{
            width: 240px;
            z-index: 5; 
        }
    }
    @include bp-small{
        &.side-nav__exp{
            padding-top: 150px;
        }
    }
    &__lst{
        font-size: 1rem;
        text-align: left;
        color: $pearson-night-rider;
        white-space: nowrap;
        &__grp{
            &__title{
                @include OpenSansSemibold;
                padding: 0px 16px 8px;
            }
            &__lnk{
                color: $pearson-night-rider;
                @include OpenSansRegular;
                padding: 8px 16px;
                border-left: 3px solid transparent;
                &.active{
                    @include OpenSansSemibold;
                    border-left: 3px solid #9e007e;
                }
            }
        }
    }
}

.screen-reader-only { position: absolute; width: 1px; clip: rect(0 0 0 0); overflow: hidden; white-space: nowrap; }