@import "~common-components-spa/dist/styles/custommixin";
@import "~common-components-spa/dist/styles/customvariables";

.notification-popup{
.userDropitems{
  background: $pearson-white 0% 0% no-repeat padding-box;
  @include opacity(1);
  @include absolute (top 40px);
  width: 100%;
  min-height: 50px;
  max-height: 280px;
  border: 0.5px solid $pearson-concrete;
  @include border-radius(0px 0px 4px 4px);
  @include box-shadow (0px 3px 6px rgba(0, 0, 0, 0.16));
  z-index: 3;
  overflow: hidden;
  &.show{
    animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    will-change: opacity;
  }
  &__head{
    padding:10px;
    border-bottom: 1px solid #f1f1f1;
    &txt{
      font-size: $fontsize;
      color: $default-pearson-blue;
      @include OpenSansSemibold;
    }
  }
  &_frmgrp{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 180px;
    @include hscrollbars();
    border-radius: 0px;
    width:100%;
    &__item{
      padding:10px;
      border-bottom: 1px solid #f1f1f1;
      &:last-child{
        border-bottom: none;
      }
      & .form__checkbox{
        &label{
          display: block; /* Fallback for non-webkit */
          display: -webkit-box;
          max-height: 50px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          &:before,&:after{
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
      // & .form__checkbox--light .form__checkboxinput:checked + .form__checkboxlabel:after{
      //   transform: translate(0.4em, -0.063em) rotate(-43deg);
      // }
    }
  }
  &__footer{
    padding:10px;
    border-top: 1px solid #f1f1f1;
    background-color: $gateway-alabaster;
  }
}

.notification-close{
    background-color: transparent;
}

.userDropitems--v2{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    @include opacity(1);
    margin-top: 2px;
    @include absolute;
    width: 100%;
    max-width: 300px;
    min-height: 50px;
    max-height: 280px;
    border: 1px solid #E9E9E9;
    @include border-radius(0px 0px 3px 3px);
    @include box-shadow (0px 7px 8px rgba(37, 37, 37, 0.08));
    z-index: 2;
    &.addtoc{
      margin-top:8px;
      &:after, &:before {
        bottom: 100%;
        left: 50%;
        border: solid $transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $pearson-white;
        border-width: 5px;
        margin-left: 36px;
      }
      &:before {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #e9e9e9;
        border-width: 6px;
        margin-left: 35px;
      }
      & .userDropitems--v2_frmgrp__item{
        border-bottom: 0.5px solid #f0f0f0;;
      }
    }
    &.deldro{
      margin-top:8px;
      right: 18px;
      min-width: 80px;
      min-height: 40px;
      &:after, &:before {
        bottom: 100%;
        left: 36%;
        border: solid $transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $pearson-white;
        border-width: 5px;
        margin-left: 36px;
      }
      &:before {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #e9e9e9;
        border-width: 6px;
        margin-left: 35px;
      }
      & .userDropitems--v2_frmgrp__item{
        border-bottom: 0.5px solid #f0f0f0;;
      }
    }
    &.courseinstance-drop-item{
      width: 200px;
      max-width: 200px;
    }
    &.duration-drop-item{
      width: 150px;
      max-width: 150px;
    }
    &.toc-drop-item{
      width: 105px;
      max-width: 105px;
      right:16px;
      top: 55px;
      border: 1px solid #D9D9D9;
      & .userDropitems--v2_frmgrp__item{
        padding: 16px 16px;
      }
    }
    &.--asmdropitem{
      width: 215px;
      max-width: 215px;
      @include bp-verysmall {
          width: 100%;
          max-width: 100%;
      }
      & .userDropitems--v2_frmgrp{
        padding:20px 16px;
        &__item{
          padding: 0px 0px 8px;
          &:last-child{
            padding:0;
          }
        }
      }
      & .form__checkbox--v2{
        padding:0;
      }
      &.form__checkbox--v2 input[type='checkbox'] + .form__checkboxlabel{
        color:#252525;
      }
      & .form__checkbox--v2 input[type='checkbox'] + .form__checkboxlabel::before{
        border: 1px solid #6A7070;
        height: 16px;
        flex: 0 0 16px;
        margin: 5px 5px 5px 0;
      }
      & .form__checkbox--v2 input[type='checkbox']:checked + .form__checkboxlabel::after{
        height: 5px;
        width: 10px;
        left: 8px;
        top: 9px;
      }
    }
    &.--crasmdropitem{
      width: 240px;
      max-width: 240px;
      & .userDropitems--v2{
        &__head{
          border-bottom: none;
          padding: 10px 16px;
          & .formsearch{
            width:100%;
            &__input{
                border-width: 0 0 1px 0;
                border-radius: 0;
                padding: .375rem 0rem;
            }
            &__cnt{
                right: 0;
            }
          }
        }
        &_frmgrp{
          padding:0 16px;
          max-height: 182px;
          &__item{
            padding:0 0 8px;
            & .form__checkboxlabel{
              max-height: 60px;
            }
            & .form__checkbox--v2{
              padding:0;
              & input[type='checkbox'] + .form__checkboxlabel::before{
                height: 16px;
                flex: 0 0 16px;
              }
              & input[type='checkbox']:checked + .form__checkboxlabel::after{
                left: 8px;
                height: 5px;
                width: 10px;
                transform: rotate(-45deg) translate(1px, 0px);
              }
            }
          }
        }
      }
    }
    &.show{
      animation-name: fadeOut;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      will-change: opacity;
    }
    &__head{
      padding:10px;
      border-bottom: 1px solid #f1f1f1;
      &txt{
        font-size: $fontsize;
        color: $default-pearson-blue;
        @include OpenSansSemibold;
      }
    }
    &_frmgrp{
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 220px;
      @include hscrollbars();
      border-radius: 0px;
      width:100%;
      &__item{
        padding:8px 16px;
        border-bottom: none;
        &--txt{
            font-size: $fontsize;
            @include OpenSansRegular;
            color:#252525;
            cursor: pointer;
        }
        &:last-child{
          border-bottom: none;
        }
        & .form__checkbox{
          &label{
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            max-height: 50px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
        }
        // & .form__checkbox--light .form__checkboxinput:checked + .form__checkboxlabel:after{
        //   transform: translate(0.4em, -0.063em) rotate(-43deg);
        // }
      }
    }
    &__footer{
      padding:10px;
      border-top: 1px solid #f1f1f1;
      background-color: $gateway-alabaster;
      &.--asmdropfoot{
        background-color:$transparent;
        border-top: 1px solid #D9D9D9;
        padding:10px 16px;
      }
      &__txt{
        color:#047A9C;
        font-size: 0.750rem;
        cursor: pointer;
        @include OpenSansRegular;
      }
    }
  }

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fullWidth {
  max-width: 100%;
  width: 100%; 
  .userDropitems--v2_frmgrp__item {
    border-bottom:0.5px solid #f0f0f0;
  }
}
.Table-Userblk{
    width: 300px;
    @include absolute(top 0);
    margin-left: 1.25rem;
    @include border-radius(4px 4px 0px 0px);
    background: #fff;
    border-left:1px solid #E7E7E7;
    border-right:1px solid #E7E7E7;
    border-top:4px solid #047A9C;
    @include box-shadow( 0 1px 1px rgba(0, 0, 0, 0.1));
    transition: ease-in-out 0.3s;
    .link {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
    }
    &.show{
        right:0
    }
    &.hide{
        right: -300px;
        z-index: -1;
        overflow: hidden;
    }
    &__close{
        color: #a2a2a2;
        cursor: pointer;
        text-align: right;
        padding: 0.5rem 1rem 0 0.5rem;
    }
    &__head{
        padding: 0.5rem 1.5rem;
        color: #252525;
        border-bottom: 1px solid #E7E7E7;
        &__name{
            @include OpenSansSemibold();
            font-size: 1rem;
            margin-bottom: 1rem;
        }
        &__email,&__country,&__users,&__status{
            @include OpenSansRegular();
            font-size: 0.75rem;
            margin:0.5rem 0;
            display: flex;
            align-items: center;
            img{
                padding-right: 5px;
            }
        }
        .stat-active{
            color: $default-pearson-blue;
            @include OpenSansSemibold();
        }
    }
    &__body{
        padding: 1.5rem 1rem 1.5rem 1.5rem;
        background: #FCFCFC;
        &__title{
            @include OpenSansSemibold();
            font-size: $fontsize;
        }
        &__check{
            @include OpenSansRegular();
            font-size: 0.75rem;
            max-height: 35vh;
            overflow-y: auto;
            @include hscrollbars();
            padding: 0 0.5rem 0 0;
            &.disabled {
                *{
                    cursor: not-allowed; 
                }
                .form__checkbox .form__checkboxlabel,.form__checkboxlabel:before{
                    cursor: not-allowed; 
                    pointer-events: none;
                }
                button{
                    pointer-events: none;
                }
            }
        }
    }
    @include bp-small{
        width: 100%;
        position: static;
        margin: 1rem 0;
    }
    &--track{
        border-top:1px solid #E7E7E7;
        width: 287PX;
        & .Table-Userblk{
           &__close{
                font-size: $fontsize;
            }
            &__head{
                padding-top: 1rem;
                padding-bottom: 1rem;
                &__name{
                    font-size: 1rem;
                    @include OpenSansRegular;
                    color: $pearson-cerulean;
                }
                &__country{
                    span{
                        font-size: 0.75rem;
                        @include OpenSansRegular;
                        color: $pearson-cerulean;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
            &__body{
                background: $pearson-white;
            }           
        }      
    }
    &--head{
        width: 400px;
        // height: 752px;
        border: 1px solid $pearson-concrete;
        opacity: 1;
        @include fixed(top 4rem);
        transition: 0.3s ease-in-out;
        @include bp-verysmall{
            width: 300px;
            top: 1.875rem;
        }  
        &.show {
            right: 20px;
            z-index: 1;
        }
        &.hide {
            right: -400px;
            transition: 0.3s ease-in-out;
        }
        & .Table-Userblk{
            &__head{
                padding: 1rem 1rem;
                &__name{
                    font-size: 1.125rem;
                    color: #005d83;
                    @include OpenSansSemibold();
                }
            }
            &__body{
                background: transparent;
                padding: 1rem 0rem;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 85vh;
                @include vscrollbars();
                @include selectscroll();
                &::-webkit-scrollbar {
                    width: 8px;
                }
                &--changebg{
                    max-height: 74vh;
                }
                &--chat{
                    max-height:62vh;
                }
                .clearall{
                    margin-right: 8px;
                    font-size: $fontsize;
                    color: #007FA3;
                    @include OpenSansRegular();
                    cursor: pointer;
                    line-height: 1;
                }
                .viewmore{
                    color: #0479AC;
                    font-size: 1rem;
                    text-decoration: underline;
                    @include OpenSansRegular();
                    cursor: pointer;
                }
            }           
            &__close{
                width: 22px;
                color: #005D83;
            }
            &__filterImage{
                height: 24px;
                width: auto;
                position: inherit;
                margin-left: 173px;
                cursor: pointer;
            }
            &__profName{
                color: $pearson-charcoal;
                font-size: $fontsize;
                @include OpenSansRegular();
                @include multi-line-ellipsis(2);
            }
            &__profDetail{
                color: $pearson-medium;
                font-size: 0.75rem;
                @include OpenSansRegular();
            }
            &__profileinfo{
                width: 345px;
                margin:2px 1rem;
            }            
            &__backgroundicon{
                margin-top: 0.4rem;
                width: 1.5rem;
                height:1.5rem;
            }
            &__Bottombtn{
                background: #fff;
                margin:12px 0;
                margin-bottom: 12px;
                @include bp-verysmall{
                    margin: 0px 0;
                }
            }
            &__chatbox{
                max-width: 100%;
                display: flex;
                flex: 1;
            }
            &__chatmsg{
                width: auto;
                max-width: 306px;
            }
            &__chatmsgleft{
                &:hover .Table-Userblk__chatmsglike{
                    display: block;
                }
                & .Table-Userblk__chatcontent{
                    background:#eaf4ff ;
                    display: inline-block;
                    position: relative;
                }
            }
            &__chatmsglike{
                background:$pearson-white; 
                @include absolute(top -15px right 2%);
                outline: unset;  
                display: none;     
                border: 1px solid #e4e3e3;
                box-shadow: 0 1px 2px rgba(0,0,0,0.2);
                @include border-radius(4px);
                z-index: 1;
            }
            &__chatmsglikeup{
                background: transparent;  
                outline: unset;
                padding: 0;
                // margin-top: -15px;
                // margin-left: auto;
            }
            &__chatcontent{
                font-size: $fontsize;
                color: $pearson-charcoal;
                @include OpenSansRegular();
                // margin: 0.5rem;
                padding: 0;
                word-break: break-word;
                border: 1px solid $pearson-concrete;
                @include border-radius(0.5rem);
                background: $pearson-white 0% 0% no-repeat padding-box;
                padding: 8px 12px;
                display: inline-block;
                & a{
                    color: $pearson-cerulean;
                }
            }
            &__chatname{
                font-size: 0.75rem;
                color: $pearson-medium;
                @include OpenSansSemibold();
                margin: 0.5rem 0.3rem;
                padding: 0;
                @include multi-line-ellipsis(2);
                max-width: 200px;
                width: auto;
            }
            &__chattime{
                font-size: 0.75rem;
                color: $pearson-medium;
                @include OpenSansRegular();
                margin: 0.5rem 0.3rem;
                padding: 0;
            }
            &__chatlabel{
                font-size: $fontsize;
                color: $pearson-charcoal;
                @include OpenSansRegular();
                padding: 1rem 0px 0px 1rem;
            }
            &__new-message{
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-top: 4px solid $pearson-cerulean !important;
                opacity: 1;
                border:1px solid $pearson-link-water;
                
                & textarea.form-control {
                    height: 64px;
                    resize: none;
                    border-radius: unset !important;
                    border: unset;
                    border-right: 1px solid $pearson-link-water;
                    padding: 1.2rem 1rem;
                    @include hscrollbars();
                    color: $pearson-charcoal;
                    &:focus {
                        border-left:1px solid #ced4da;
                        outline: 0;
                        box-shadow:unset;
                        color: $pearson-charcoal;
                    }
                    &::placeholder {
                        font-size: $fontsize;
                        color: $pearson-charcoal;
                        @include OpenSansRegular();
                        border-color: unset;
                    } 
                    &::-webkit-input-placeholder,:-moz-placeholder,::-moz-placeholder,:-ms-input-placeholder{
                        color: $pearson-charcoal;
                    }                   
                }
                & .input-group-btn{
                    background: transparent;
                    &:focus {
                        border-color: unset;
                        outline: unset;
                        box-shadow:unset;
                    }
                    & span{
                        font-size: $fontsize;
                       color:$pearson-medium;
                       @include OpenSansRegular();
                    }
                }
            }

        }
    }
    &--notificationrhs{
        width: 400px;
        height: 752px;
        border: 1px solid $pearson-concrete;
        opacity: 1;
        @include fixed(top 3rem);
        transition: 0.3s ease-in-out;
        @include bp-verysmall{
            width: 300px;
            top: 1.875rem;
        }
        &.show {
            right: 0px;
            z-index: 1;
        }
        &.hide {
            right: -400px;
            transition: 0.3s ease-in-out;
        }
        & .Table-Userblk{
            &__head{
                padding: 1rem 1rem;
                &__name{
                    font-size: 1.125rem;
                    color: #005d83;
                    @include OpenSansSemibold();
                }
            }
            &__filter{
                position: absolute;
                z-index: 1;
                background: $pearson-white;
                box-shadow: 0px 0.49rem 0.5rem #0000000D;
                transition: 1s all;
                animation: 1s slidedown !important;
            }
            &__body{
                background: transparent;
                padding: 1.5rem 1rem;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 85vh;
                @include vscrollbars();
                @include selectscroll();
                &::-webkit-scrollbar {
                    width: 8px;
                }
                &--changebg{
                    max-height: 74vh;
                }
                &--chat{
                    max-height:62vh;
                }
            }
            &__close{
                width: 22px;
                color: #005D83;
            }
            &__filterImage{
                height: 24px;
                width: auto;
                position: inherit;
                margin-left: 173px;
                cursor: pointer;
            }
            &__profName{
                color: $pearson-charcoal;
                font-size: $fontsize;
                @include OpenSansRegular();
                @include multi-line-ellipsis(2);
            }
            &__profDetail{
                color: $pearson-medium;
                font-size: 0.75rem;
                @include OpenSansRegular();
            }
            &__profileinfo{
                width: 200px;
                margin:2px 0.5rem;
            }
            &__backgroundbox{
                width: 115px;
                height: 80px;
                background: $pearson-white 0% 0% no-repeat padding-box;
                border: 1px solid $pearson-concrete;
                @include border-radius(4px);
                opacity: 1;
                text-align: center;          
                & p{
                    font-size: $fontsize;
                    @include multi-line-ellipsis(2);
                    word-break: break-word;
                    width: 100px;
                    margin: 0 auto;
                }
                &--backgroundcerulean{
                    background-color: $pearson-cerulean;
                }
                &--backgroundprussian{
                    background-color: #002e60;
                }
                &--backgrounddrakblue{
                    background-color:#005d83;
                }
                &--backgroundmoonlight{
                    background-color:$pearson-moonlight
                }
                &--backgrounddarkgray{
                    background-color:#a9a9a9;
                }
                &--backgroundmustard{
                    background-color:#ffe15c
                }
                &--backgroundblueberry{
                    background-color: #64b0fb;
                }
                &--backgroundwhite{
                    background-color:$pearson-white 0% 0% no-repeat padding-box;
                }
                &--backgroundconcrete{
                    background-color:#D4EAE4;
                }
                &--backgroundconcre{
                    background-color:#EAF4FF;
                }
            }
            &__backgroundicon{
                margin-top: 0.4rem;
                width: 1.5rem;
                height:1.5rem;
            }
            &__Bottombtn{
                background: #fff;
                margin:12px 0;
                margin-bottom: 12px;
                @include bp-verysmall{
                    margin: 0px 0;
                }
            }
            &__chatbox{
                max-width: 100%;
                display: flex;
                flex: 1;
            }
            &__chatmsg{
                width: auto;
                max-width: 306px;
            }
            &__chatmsgleft{
                &:hover .Table-Userblk__chatmsglike{
                    display: block;
                }
                & .Table-Userblk__chatcontent{
                    background:#eaf4ff ;
                    display: inline-block;
                    position: relative;
                }
            }
            &__chatmsglike{
                background:$pearson-white; 
                @include absolute(top -15px right 2%);
                outline: unset;  
                display: none;     
                border: 1px solid #e4e3e3;
                box-shadow: 0 1px 2px rgba(0,0,0,0.2);
                @include border-radius(4px);
                z-index: 1;
            }
            &__chatmsglikeup{
                background: transparent;  
                outline: unset;
                padding: 0;
                // margin-top: -15px;
                // margin-left: auto;
            }
            &__chatcontent{
                font-size: $fontsize;
                color: $pearson-charcoal;
                @include OpenSansRegular();
                // margin: 0.5rem;
                padding: 0;
                word-break: break-word;
                border: 1px solid $pearson-concrete;
                @include border-radius(0.5rem);
                background: $pearson-white 0% 0% no-repeat padding-box;
                padding: 8px 12px;
                display: inline-block;
                & a{
                    color: $pearson-cerulean;
                }
            }
            &__chatname{
                font-size: 0.75rem;
                color: $pearson-medium;
                @include OpenSansSemibold();
                margin: 0.5rem 0.3rem;
                padding: 0;
                @include multi-line-ellipsis(2);
                max-width: 200px;
                width: auto;
            }
            &__chattime{
                font-size: 0.75rem;
                color: $pearson-medium;
                @include OpenSansRegular();
                margin: 0.5rem 0.3rem;
                padding: 0;
            }
            &__chatlabel{
                font-size: $fontsize;
                color: $pearson-charcoal;
                @include OpenSansRegular();
                padding: 1rem 0px 0px 1rem;
            }
            &__new-message{
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-top: 4px solid $pearson-cerulean !important;
                opacity: 1;
                border:1px solid $pearson-link-water;
                
                & textarea.form-control {
                    height: 64px;
                    resize: none;
                    border-radius: unset !important;
                    border: unset;
                    border-right: 1px solid $pearson-link-water;
                    padding: 1.2rem 1rem;
                    @include hscrollbars();
                    color: $pearson-charcoal;
                    &:focus {
                        border-left:1px solid #ced4da;
                        outline: 0;
                        box-shadow:unset;
                        color: $pearson-charcoal;
                    }
                    &::placeholder {
                        font-size: $fontsize;
                        color: $pearson-charcoal;
                        @include OpenSansRegular();
                        border-color: unset;
                    } 
                    &::-webkit-input-placeholder,:-moz-placeholder,::-moz-placeholder,:-ms-input-placeholder{
                        color: $pearson-charcoal;
                    }                   
                }
                & .input-group-btn{
                    background: transparent;
                    &:focus {
                        border-color: unset;
                        outline: unset;
                        box-shadow:unset;
                    }
                    & span{
                        font-size: $fontsize;
                       color:$pearson-medium;
                       @include OpenSansRegular();
                    }
                }
            }

        }
      
    }
    &__participants{
        width: 400px; 
        height: 100%; 
        border: none;
        @include fixed(top 3rem);
        transition: 0.3s ease-in-out;;
        top: 3rem;
        box-shadow: -7px 0px 8px #25252514;
        z-index: 1;
        @include bp-verysmall{
            width: 300px;
            top: 1.875rem;
        }
        &.hide{
            right: -410px;
            z-index: 1;
            overflow: hidden;
            transition: 0.3s ease-in-out;
        }
        & .Table-Userblk{
            &__head{
                padding: 1rem 1rem;
                &__name{
                    font-size: 1.125rem;
                    color: #005d83;
                    @include OpenSansSemibold();
                }
            }
            &__body{
                padding: 1.5rem 1rem 1.5rem 1rem;
                background: $pearson-white;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 85vh;
                @include vscrollbars();
                @include selectscroll();
                &::-webkit-scrollbar {
                    width: 8px;
                }
            }
            &__close{
                width: 1.375rem;
                color: #005D83;
            }
            &__profilename{
                margin-bottom: 0.75rem;
                font-size:$fontsize;
                @include OpenSansRegular();
                & span{
                    @include OpenSansSemibold();
                }
            }
            &__joinlink{
                width: 257px;
                font-size:$fontsize;
                font-style: italic;
                word-break: break-word;
                color: #005D83;
                @include OpenSansRegular();
            }
            &__currentmeeting{
                margin-top: 1.5rem;
                margin-bottom: 0.75rem;
                font-size:$fontsize;
                @include OpenSansRegular();
                color: $pearson-charcoal;
            }
            &__inmeeting{
                margin-top: 1.5rem;
                margin-bottom: 1rem;
                font-size:$fontsize;
                @include OpenSansRegular();
                & span{
                    @include OpenSansSemibold();
                }
            }
            &__profName{
                color: $pearson-charcoal;
                font-size: $fontsize;
                @include OpenSansRegular();
                @include multi-line-ellipsis(2);
            }  
            &__profDetail {
                color: $pearson-medium;
                font-size: 0.75rem;
                @include OpenSansRegular();
            } 
            &__socialicon{
                position: relative;
                & img{
                    min-width: 24px;
                    min-height: 24px;
                }
            }        
        }
        & .formsearch{
            width: 100%;
        }
    }
    &__nothead{
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 38vh;
        @include vscrollbars();
        @include selectscroll();
    }
}
.custom-link{
    color: $default-pearson-blue;
    cursor: pointer;
    text-decoration: underline;
}

.Table-Userblk__participants .Table-Userblk__list{
    margin: 4px 0px;
    padding: 10px 0
}

.Table-Userblk__inmeeting button:hover .button--v2__imgfirst { filter: brightness(0) invert(1); } 
}